import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ViewUI from 'view-design';
import en from 'view-design/dist/locale/en-US';
import zh from 'view-design/dist/locale/zh-CN';
import { exCN } from './cn'
import { exEN } from './en'

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'zh-Hans',
    messages:{
        'zh-Hans': Object.assign(exCN, zh),
        'en': Object.assign(exEN, en)
    }
});

Vue.use(ViewUI, {
  i18n: function(path, options) {
    let value = i18n.t(path, options)
    if (value !== null && value !== undefined) {
      return value
    }
    return ''
  }
})

export default i18n;
