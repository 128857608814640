import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/libs/api'
import util from '@/libs/util'
import cookies from 'vue-cookies'
import { asyncRouterMap, constantRouterMap, resetRouter } from '@/router/index'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        default_bucket_tokens: {},
        department_tree: {},
        user_tree: {},
        up_tree: {},
        unread_count: 0,
        username: '',
        organization_name: '',
        organization_id: null,
        routers: constantRouterMap,
        addRouters: [],
        avatar_uri: '',
        rolesList: [],//account,user,device,content,deploy
        config: {},
        terminals_category: [],
        programs_category: [],
        templates_category: [],
        panels: [],
        cloud_api: '',
        tag_support: false,
        cym_support: false,
        areas: [],
        organizationList: [],
        lang: 'zh-Hans'
    },
    getters:{
        addRouters: state => state.addRouters,
        menus: state => (keys) => {
            if (state.addRouters.length === 0) return
            let data = state.addRouters[0].children,
                len = data.length,
                menus = [];
            for (let i = 0; i < len; i++) {
                if (keys.indexOf(data[i].name) !== -1) {
                    menus.push(data[i])
                }
            }
            return menus
        }
    },
    mutations:{
        SET_PROGRAMS_CATEGORY (state, programs_category) {
            state.programs_category = programs_category
        },
        SET_TERMINALS_CATEGORY (state, terminals_category) {
            state.terminals_category = terminals_category
        },
        SET_TEMPLATES_CATEGORY (state, templates_category) {
            state.templates_category = templates_category
        },
        SET_CONFIG (state, config) {
            state.config = config
        },
        SET_BUCKET_TOKENS (state, bucket_tokens) {
            state.default_bucket_tokens = bucket_tokens
        },
        SET_DEPARTMENT_TREE (state, department_tree) {
            state.department_tree = department_tree
        },
        SET_USER_TREE (state, user_tree) {
            state.user_tree = user_tree
        },
        SET_UP_TREE (state, up_tree) {
            state.up_tree = up_tree
        },
        SET_ROUTERS (state, routers) {
            let layout = {},
                len = routers.length,
                new_routers = [],
                menus = [],
                new_menus = [];
            for (let i = 0; i < len; i++) {
                if (routers[i].name === 'layout') {
                    layout = {
                        name: routers[i].name,
                        path:  routers[i].path,
                        redirect: routers[i].redirect,
                        component: routers[i].component
                    }
                    menus = routers[i].children
                    new_routers.push(layout)

                } else {
                    new_routers.push(routers[i])
                }
            }
            for (let j = 0, len = menus.length; j < len; j++) {
                if (menus[j].name === 'organization' && !state.default_bucket_tokens.org) {
                    //个人登录时不添加企业中心路由
                    continue
                } else if (menus[j].name === 'spaces' && !state.default_bucket_tokens.org) {

                    //个人登录时不添加企业空间路由
                    let spaces = menus[j].children,
                        len3 = spaces.length;
                    for (let k = 0; k < len3; k++) {
                        if (spaces[k].name === 'companySpaces') {
                            spaces.splice(k, 1)
                            break
                        }
                    }
                }
                //企业登录时做菜单权限处理
                if (state.default_bucket_tokens.org) {
                    if (menus[j].name === 'spaces') {
                        let spacesChildren = []
                        for (let i = 0, len = menus[j].children.length; i < len; i++) {
                            if (menus[j].children[i].name === 'companySpaces' && (state.rolesList.indexOf('content') === -1 && state.rolesList.indexOf('deploy') === -1)) continue
                            if (menus[j].children[i].name === 'templateSpaces' && state.rolesList.indexOf('content') === -1) continue
                            spacesChildren.push(menus[j].children[i])
                        }
                        menus[j].children = spacesChildren
                    }
                    if (menus[j].name === 'screens') {
                        if (state.rolesList.indexOf('content') === -1 && state.rolesList.indexOf('device') === -1 && state.rolesList.indexOf('deploy') === -1) continue
                        let screensChildren = []
                        for (let i = 0, len = menus[j].children.length; i < len; i++) {
                            if (menus[j].children[i].name === 'programs' && state.rolesList.indexOf('content') === -1 && state.rolesList.indexOf('deploy') === -1) continue
                            if (menus[j].children[i].name === 'mediaScreens' && !state.cym_support) continue
                            screensChildren.push(menus[j].children[i])
                        }
                        menus[j].children = screensChildren
                    }
                    if (menus[j].name === 'tags') {
                        if (!state.tag_support) continue
                        if (state.rolesList.indexOf('content') === -1 && state.rolesList.indexOf('device') === -1 && state.rolesList.indexOf('deploy') === -1) continue
                        let tagsChildren = []
                        for (let i = 0, len = menus[j].children.length; i < len; i++) {
                            tagsChildren.push(menus[j].children[i])
                        }
                        menus[j].children = tagsChildren
                    }
                    if (menus[j].name === 'others' && state.rolesList.indexOf('data') === -1) continue
                    if (menus[j].name === 'organization') {
                        if (state.rolesList.indexOf('account') === -1 && state.rolesList.indexOf('user') === -1) {
                            continue
                        } else {
                            let newChildren = []
                            for (let k = 0, len = menus[j].children.length; k < len; k ++) {
                                if (menus[j].children[k].name === 'setting' && state.rolesList.indexOf('account') === -1) {
                                    continue
                                }
                                if (menus[j].children[k].name !== 'setting' && state.rolesList.indexOf('user') === -1) {
                                    continue
                                }
                                newChildren.push(menus[j].children[k])
                            }
                            menus[j].children = newChildren
                        }
                    }
                } else {
                    if (state.rolesList.length === 0 && menus[j].name === 'others') continue
                    if (menus[j].name === 'screens') {
                        let screensChildren = []
                        for (let i = 0, len = menus[j].children.length; i < len; i++) {
                            if (menus[j].children[i].name === 'smartTags') continue
                            screensChildren.push(menus[j].children[i])
                        }
                        menus[j].children = screensChildren
                    }
                }
                new_menus.push(menus[j])
            }

            layout.children = new_menus

            state.addRouters = new_routers
            state.routers = constantRouterMap.concat(new_routers)

        },
        GET_UNREAD_COUNT (state, count) {
            state.unread_count = count
        },
        SET_USERNAME (state, username) {
            state.username = username
        },
        SET_ORGANIZATION_NAME (state, organization_name) {
            state.organization_name = organization_name
        },
        SET_ORGANIZATION_ID (state, organization_id) {
            state.organization_id = organization_id
        },
        SET_AVATAR_URI (state, avatar_uri) {
            state.avatar_uri = avatar_uri
        },
        SET_ACCESS_RIGHTS (state, access_right_list) {
            state.rolesList = access_right_list
        },
        SET_PANELS (state, panels) {
            state.panels = panels
        },
        SET_CLOUD_API (state, cloud_api) {
            state.cloud_api = cloud_api
        },
        SET_TAG_SUPPORT (state, tag_support) {
            state.tag_support = tag_support
        },
        SET_CYM_SUPPORT (state, cym_support) {
            state.cym_support = cym_support
        },
        SET_AREAS_LIST (state, areas) {
            state.areas = areas
        },
        SET_ORGANIZATION_LIST (state, organizationList) {
            state.organizationList = organizationList
        },
        SET_LANG (state, lang) {
            state.lang = lang
        }
    },
    actions:{
        GET_ORGANIZATION_LIST({ commit }) {
            return api.get('organizations/search_for_user_login').then(({ data }) => {
                commit('SET_ORGANIZATION_LIST', data)
                return data
            }).catch(() => {
                return []
            })
        },
        GET_TAG_SUPPORT({ commit }) {
            return api.get('website').then(({ data }) => {
                commit('SET_TAG_SUPPORT', data.tag_support)
                commit('SET_CYM_SUPPORT', data.cym_support)
            })
        },
        GET_PROGRAMS_CATEGORY({ commit }) {
            return api.get('programs/category').then(({ data }) => {
                commit('SET_PROGRAMS_CATEGORY', data)
            }).catch(() => {})
        },
        GET_TERMINALS_CATEGORY({ commit }) {
            return api.get('terminals/category').then(({ data }) => {
                commit('SET_TERMINALS_CATEGORY', data)
            }).catch(() => {})
        },
        GET_TEMPLATES_CATEGORY({ commit }) {
            return api.get('templates/category_list').then(({ data }) => {
                commit('SET_TEMPLATES_CATEGORY', data)
            }).catch(() => {})
        },
        GET_CONFIG({ commit }) {
            return api.get('../config.json').then(config => {
                commit('SET_CONFIG', config)
            }).catch(() => {})
        },
        GET_BUCKET_TOKENS ({ commit }) {
            return new Promise((resolve, reject) => {
                api.get('me/status').then(({ default_bucket_tokens, avatar_uri, user_name, organization_name, organization_id })=>{
                    commit('SET_BUCKET_TOKENS', default_bucket_tokens)
                    commit('SET_USERNAME', user_name)
                    if (organization_name) {
                        commit('SET_ORGANIZATION_NAME', organization_name)
                        commit('SET_ORGANIZATION_ID', organization_id)
                    }
                    commit('SET_AVATAR_URI', `${avatar_uri}?${util.randomWord(20)}`)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        GET_DEPARTMENT_TREE({ commit }) {
            return new Promise((resolve, reject) => {
                //当前登录用户的所属企业的节点树
                api.get('organizations/current/departments/tree').then(({ data }) => {
                    commit('SET_DEPARTMENT_TREE', data)
                    resolve()
                }).catch(() => {
                    reject()
                })
                //当前登录用户的节点树
                // api.get('me/departments/tree').then(({ data }) => {
                //     commit('SET_USER_TREE', data)
                //     resolve()
                // }).catch(() => {
                //     reject()
                // })
            })
        },
        GET_USER_TREE({ commit }) {
            return new Promise((resolve, reject) => {
                //当前登录用户的节点树
                api.get('me/departments/tree').then(({ data }) => {
                    commit('SET_USER_TREE', data)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        GET_UP_TREE({ commit }) {
            return new Promise((resolve, reject) => {
                api.get('me/departments/up_tree').then(({ data }) => {
                    commit('SET_UP_TREE', data)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        GET_ACCESS_RIGHTS({ commit }) {
            return api.get('me/access_right_list').then(({ access_right_list }) => {
                commit('SET_ACCESS_RIGHTS', access_right_list)
            })
        },
        async Logout ({ dispatch }) {
            if (cookies.get('Authorization')) {
                await api.delete('auth/access_token').then(() => {
                    localStorage.removeItem('token');
                    cookies.remove('Authorization')
                })
            }
            return new Promise((resolve) => {
                dispatch('CLEAR_DATA')

                resetRouter()
                resolve()
            })
        },
        CLEAR_DATA ({ commit }) {
            commit('SET_PROGRAMS_CATEGORY', [])
            commit('SET_TERMINALS_CATEGORY', [])
            commit('SET_BUCKET_TOKENS', {})
            commit('SET_DEPARTMENT_TREE', {})
            commit('SET_USER_TREE', {})
            commit('SET_UP_TREE', {})
            commit('SET_USERNAME', '')
            commit('SET_ORGANIZATION_NAME', '')
            commit('SET_ORGANIZATION_ID', null)
            commit('SET_ROUTERS', [])
            commit('SET_AVATAR_URI', '')
            commit('SET_ACCESS_RIGHTS', [])
            commit('SET_ORGANIZATION_LIST', [])
        },
        RESET_ROUTES ({ commit }) {
            commit('SET_ROUTERS', [])
            resetRouter()
        },
        async GenerateRoutes ({ dispatch, commit, state }) {
            await dispatch('GET_BUCKET_TOKENS')
            await dispatch('GET_USER_TREE')
            await dispatch('GET_ACCESS_RIGHTS')
            await dispatch('GET_TERMINALS_CATEGORY')
            await dispatch('GET_PROGRAMS_CATEGORY')
            await dispatch('GET_TEMPLATES_CATEGORY')
            dispatch('GET_AREAS_LIST')
            if (state.rolesList.indexOf('data') !== -1) await dispatch('GET_PANELS')
            //企业登录时获取
            if (state.default_bucket_tokens.org) {
                await dispatch('GET_DEPARTMENT_TREE')
                await dispatch('GET_UP_TREE')
                await dispatch('GET_TAG_SUPPORT')
            }
            return new Promise((resolve) => {
                commit('SET_ROUTERS', util.deepClone(asyncRouterMap))
                resolve()
            })
        },
        GET_UNREAD_COUNT ({ commit }) {
            return api.get('messages/unread_count').then(({ count }) => {
                commit('GET_UNREAD_COUNT', count)
            })
        },
        GET_PANELS ({ commit, state }) {
            let bucket_token = state.default_bucket_tokens.org
            if (!bucket_token) bucket_token = state.default_bucket_tokens.user
            let params = {
                bucket_token: bucket_token
            }
            return new Promise((resolve, reject) => {
                api.get('data_panels/list', { params }).then(({ data }) => {
                    commit('SET_PANELS', data)
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        },
        GET_AREAS_LIST  ({ commit }) {
            return api.get('auth/captchas/sms_area_code_list').then(({ data=[] }) => {
                commit('SET_AREAS_LIST', data)
            })
        }
    }
});