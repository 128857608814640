export const exEN={
    message: {
        success: 'Successful',
        fail: 'Failure',
        select: 'Please select',
        errTip: 'Error tips'
    },
    content: {
        
    },
    common: {
        cancel: 'Cancel',
        confirm: 'Confirm',
        total: 'Total',
        input: 'Input',
        select: 'Please select',
        searchLibrary: 'Search for name',
        all: 'All',
        del: 'Delete',
        selectAll: 'Select all',
        selected: 'Selected ',
        content: 'Content',
        post: 'Release',
        edit: 'Edit',
        createACopy: 'Create a copy',
        rename: 'Rename',
        delTip: 'Delete tips',
        delCon: 'After deleting the resource, it cannot be retrieved again. Please operate with caution.',
        confirmDel: 'Confirm to delete',
        sureDel: 'Are you sure to delete it?',
        success: 'Successful',
        tip: 'Tips',
        action: 'Operation',
        inputNumber: 'Please enter the number',
        add: 'New',
        limit: 'Cannot exceed 16 characters',
        limit2: 'Cannot be less than 8 characters',
        state: 'Status',
        previousStep: 'Previous step',
        nextStep: 'Next step',
        delAll: 'Delete all',
        save: 'Save',
        saveAs: 'Save as',
        saveAndPost: 'Save and release',
        upTo32Characters: 'Maximum 32 characters',
        operationTime: 'Operation time',
        operator: 'Operating user',
        gotIt: 'Got it',
        pixel: 'Pixel',
        required: 'Cannot be empty',
        ok: 'OK',
        reset: 'Reset',
        search: 'Search',
        setting: 'Setting',
        copySuccess: ' Copy successfully',
        copyFailed: 'Copy failed',
    },
    layout:{
        sysName: 'Jofee Show',
        logOut: 'Log out',
        switchAccount: 'Switch account',
        currentUse: 'Current use',
        home: 'Home',
        applet: 'Mini program',
        scan: 'Scan',
        description: 'Screen projection is so easy',
    },
    navigation: {
        spaces: 'Media material',
        personSpaces: 'Personal space',
        companySpaces: 'Enterprise space',
        templateSpaces: 'Template space',
        templateDesign: 'Template design',
        screens: 'Digital media marketing',
        programs: 'Programs',
        programEdit: 'Program edit',
        mediaScreens: 'Smart digital display',
        smartTags: 'Smart tags',
        personalCenter: 'Personal center',
        personalSettings: 'Personal setting',
        organization: 'Enterprise center',
        structure: 'Organizational structure',
        employee: 'Staff management',
        role: 'Role management',
        messages: 'My messages',
        setting: 'Enterprise setting',
        others: 'AI material',
        goods: 'AI data',
        dataTemplates: 'AI templates',
        tags: 'Smart labels',
    },
    pages: {
        login: {
            desp: 'Digital media one-stop management platform',
            passwordLogin: 'Log via password',
            messageLogin: 'Login via SMS',
            scanQR: 'Scan QR code with WeChat',
            inputPhoneNumber: 'Please enter your mobile phone number',
            inputPassword: 'Please enter a 6-30 digit password',
            inputGraphCode: 'Please enter the graphic verification code',
            SMSCodeLogin: 'Login via SMS verification code',
            changePassword: 'Change password',
            forgetPassword: 'Forget password',
            login: 'Log in',
            inputCode: 'Please enter the verification code',
            getCode: 'Get',
            passwordLogin2: 'Account password login',
            readAndagree: 'Read and agree to the agreement to obtain QR code',
            description: 'Opening a new world of digital media',
            phoneNumberLogin: 'Login with mobile phone number',
            phoneNumberRegistration: 'Register with mobile phone number',
            agreed: 'I have read and agree',
            userAgreement: 'User agreement',
            and: 'And',
            privacyPolicy: 'Privacy Policy',
            inputNewPassword: 'Please enter your new password',
            rememberPassword: 'Remember the password',
            toLogin: 'Go and log in',
            registerAnAccount: 'Register an account',
            haveAnAccount: 'Do you already have an account?',
            verifyAndContinue: 'Verify and continue',
            enterpriseRegistration: 'Enterprise registration',
            enterpriseCreation: 'Create new enterprise',
            inputEnterpriseName: 'Please enter the enterprise (team) name',
            selectIndustryType: 'Please select an industry type',
            inputInvitationCode: 'Please enter the invitation code',
            contactCustomerService: 'The invitation code needs to be applied from the platform, please contact customer service',
            confirmRegistration: 'Confirm to register',
            loginEnterpriseAccount: 'Log in to an enterprise Account',
            remenberSelection: 'Remember your choice. You can only reselect when you actively log out.',
            notThisAccount: 'this is not the right account?',
            loginAgain: 'Login again',
            personalAccountLogin: 'Manage my personal resources',
            input4Digits: 'Please enter 4 digits',
            inputDigitsAndLetters: 'Please enter 6 digits and English letters',
            readAndAgreeToLogin: 'Read and agree to the agreement before logging in',
            readAndAgreeToRegister: ' Read and agree to the agreement before registering',
            joined: 'You have joined the enterprise',
            bindPhoneNumber: 'Bind mobile phone number',
            joined2: 'You have joined',
            continue: 'Your personal account has been automatically created for you, you can continue to create an enterprise',
            searchCountry: 'Search your country and region',
            upgrade: 'Upgrade to enterprise',
            upgradeTip: 'All the resources under your personal account will be transferred to the enterprise',
            confirmUpgrade: 'Confirm the upgrade',
            currentAccount: 'Current login account',
            invited: 'Invite you to join',
            loginAndiJoin: 'Log in and join the enterprise',
            joinNow: 'Join now',
            enterCompany: 'Enter the enterprise',
            backHome: 'Back to home page',
            loginPersonalAccount: 'Login personal account',
            loginCompanyAccount: 'Log in to an enterprise Account',
        },
        label: {
            unit: '',
            labelMag: 'Label management',
            label: 'Label',
            labels: 'Labels',
            editLabel: 'Edit Label',
            add: 'New label',
            inputName: 'Please enter a label name',
            limit: 'The label name cannot exceed',
            character: 'character',
            characters: 'characters',
            addOrSearch: 'Add or search for labels',
            create: 'Create new label',
            search: 'Search labels',
            delTit: 'Delete labels',
            delCon: 'Do you want to delete labels? Content containing the label will not be deleted after deletion',
            setLabel: 'Label setting',
        },
        space: {
            copyTo: 'Copy to enterprise',
            createTemplate: 'Create a template',
            uploadMaterials: 'Upload material',
            noMaterials: 'No material',
            materialName: 'Material name',
            resolutionRatio: 'Resolution ratio',
            materialSize: 'Material size',
            postTip: 'After the selection, the release will clear the playback plan of the selected screen, and only the program of the release will be retained',
            confirmSelect: 'Confirm selection',
            cancel: 'Cancel',
            inputNameSearch: 'Enter material name',
            select: 'Please select material',
            add: 'Add material',
            image: 'Image',
            video: 'Video',
            searchMaterials: 'Search Materials',
            baseInfo: 'Basic information',
            format: 'Format',
            size: 'Size',
            uploadTime: 'Upload time',
            checkFailed: 'The following content is detected as illegal and cannot be uploaded',
            checking: 'Under safety inspection',
            closeTip: 'After closing, the upload result will no longer be notified',
            wait: 'Just wait',
            confirmClose: 'Confirm to close',
            hide: 'Close and upload in the background',
            loading: 'Loading',
            noMore: 'No more data',
            closeTip2: 'After closing, resources need to be uploaded again. Do you want to cancel?',
            think: 'Let me think',
            confirmCancel: 'Confirm to cancel',
            uploading: 'Uploading',
            nameRequired: 'The name cannot be empty',
            nameLength: 'The name cannot exceed 32 characters',
            imageSizeLimit: 'The image size cannot exceed 20M',
            imageSizeLimit2: 'The width or height of the picture cannot exceed 30000 pixels',
            imageSizeLimit3: 'The total number of pixels in the picture cannot exceed 2',
            videoSizeLimit: 'The video size cannot exceed 1G',
            audioSizeLimit: 'The audio size cannot exceed 1G',
            fileCountLimit: 'A maximum of 20 files can be uploaded at a time',
            unsupportedFormat: 'Unsupported format',
            audio: 'Audio frequency',
            gif: 'Animated picture',
            content: ' content ',
            contents: ' contents',
            unit: '',
        },
        goods: {
            pc: 'Pc',
            pcs: 'Pcs',
            data: 'Data',
            add: 'New data',
            edit: 'Edit data',
            fieldsMag: 'Data field management',
            connectStore: 'Docking store',
            panelsMag: 'Docking store',
            inputToSearch: 'Enter name or barcode',
            quickPost: 'Quick release',
            noData: 'No data available',
            delCon: 'This operation is for all stores, if other stores are still using the product, please do not delete it',
            delCon2: 'Are you sure to delete?',
            connected: 'Docked stores',
            more: 'Docked stores',
            addPanel: 'Docked stores',
            editPanel: 'Edit pane',
            inputPanelKey: 'Please enter a unique value for the panel, which cannot be repeated',
            panelName: 'Panel name',
            inputPanelName: 'Please enter the name of the panel, which cannot be repeated',
            panelsMagTip: 'The data component in the template supports multiple panels. You can select different panels to display different layouts for data',
            panel: 'Panel',
            inputDigitsAndLetters: 'Please enter English letters and numbers',
            selectTmpl: 'Select template',
            selectScreen: 'Select screen',
            showCode: 'Show docking code',
            generateCode: 'Generate docking code',
            connectCode: 'Docking code',
            clearCode: 'Clear docking code',
            copyCode: 'Copy docking code',
            connectTip: 'The store docking program configures the "docking code" of the corresponding organization node, and the docking with the organization node can be completed',
            connectTime: 'Docking time',
            applyToStore: 'Apply to store',
            limit: 'The value cannot exceed 512 characters',
            customFields: 'Custom field',
            existingFields: 'Existing fields',
            selectFile: 'Select file',
            exclusiveImage: 'Exclusive image',
            exclusiveVideo: 'Exclusive video',
            provideTip: 'The system provides the corresponding data',
            useDirectly: 'The system provides the corresponding data?',
            selecLocalFile: 'Select the local file',
            use: 'Use directly',
            unsupportedFormat: 'Unsupported format',
            addField: 'New field',
            editField: 'Edit field',
            fieldKey: 'Field key',
            fieldName: 'Field name',
            fieldType: 'Field type',
            matchTmpls: 'Matching templates have been automatically filtered for you',
            selectData: 'Select data',
            dataCenter: 'Data center',
            state: 'Data status',
            createData: 'New data',
            selectedData: 'Selected data',
            bindData: 'Bind data',
            dataPanel: 'Bind data',
            confirmClear: 'Confirm to clear',
            clearPassCodeTip: 'After clearing the docking code, the docking will become invalid and data will no longer be synchronized, but the docked data will not be cleared.',
            clearPassCodeTip2: '2、Regenerate the docking code and reconfigure the new docking code in the docking program. This will clear the old docked data and synchronize new docking data.',
            confirrmRefresh: 'Confirm to refresh',
            refreshTip: 'After updating the docking code, the docking will become invalid and data will no longer be synchronized, but the docked data will not be cleared',
            refreshTip2: 'After reconfiguring a new docking code in the docking program, the old docked data will be cleared and new docking data will be synchronized.',
            dynamicCode: 'Dynamic verification code',
            selectLabel: 'Please select the data label you want to set',
        },
        screen: {
            inputSearch: 'Search screen name',
            inputSearch2: 'Enter screen name / ID',
            noScreen: 'No screen',
            unit: '',
            screen: ' screen',
            screens: ' screens',
            allScreens: 'All screens',
            group: 'Screen grouping',
            addScreen: 'New screen',
            dir: 'Screen orientation',
            category: 'Product type',
            volume: 'Volume',
            onOff: 'On-off setting',
            transfer: 'Screen transfer',
            transfer2: 'Transfer',
            setNetWork: 'Network configuration',
            online: 'Online',
            offline: 'Offline',
            record: 'Transfer record',
            screenRecord: 'Screen transfer record',
            transferSource: 'Transfer(from)',
            transferTarget: 'Transfer(target)',
            companyInternal: 'Enterprise internal',
            otherAccount: 'Other account',
            transferTip: 'This action will turn the selected',
            transferTip2: 'Transfer to other organizational departments',
            transferTip3: 'Transfer to another account',
            transferTip4: 'Cannot be undone. Please operate with caution.',
            inputAccountID: 'Please enter the account ID',
            clearRecordsTip: 'After being selected, the transfer record is cleared',
            clearRecords: 'Clear transfer record',
            clearPlan: 'Clear play plan',
            clearPlanTip: 'If selected, the transfer will clear the play plan of the selected screen',
            and: 'and',
            clearPlan2: "Clear the screen's playback schedule",
            goToTransfer: 'You will transfer',
            to: 'to',
            transferTip5: 'This action cannot be undone! Confirm transfer',
            upgrade: 'Upgrades',
            latestSysVer: 'Latest system version',
            currentVer: 'Current version',
            latestAppVer: 'Latest application version',
            sysUpdateCon: 'System update content',
            appUpdateCon: 'Apply updates',
            tipTit: 'Warm reminder',
            tipCon: 'This upgrade includes a system upgrade, and the screen cannot be used during the upgrade',
            tipCon2: 'Upgrade will retain your original data, please feel free to upgrade',
            waiting: 'The upgrade command has been sent. Please wait for the device upgrade',
            horizontal: 'Landscape screen',
            vertical: 'Portrait screen',
            noGroup: 'No grouping',
            plan: 'Play plan',
            addScreenTip: 'Add a screen for normal use',
            addTipCon: 'Whether to add a screen to the group immediately after creating the group successfully',
            delGroupTip: 'Deleting a group does not affect the existing playback plan of the screen. Do you want to confirm the deletion',
            addGroup: 'New group',
            groupName: 'Group name',
            inputGroupName: 'Please enter a group name',
            selectDir: 'Please select the screen orientation',
            addGroupTip: 'Groups can only be created for screens in the same direction',
            selectableScreen: 'Optional screen',
            notJoined: 'Ungrouped',
            joined: 'You have added to other group',
            selectedScreen: 'Selected screen',
            removeTip: 'Confirm to move all selected screens out of the group',
            removeTip2: 'Confirm to move the screen out of the group',
            updatePlan: 'The play plan for the selected screen is updated to the play plan for the group',
            bindingCode: 'Binding code',
            inputBindingName: 'Please enter the binding code',
            name: 'Screen name',
            numberOfScreens: 'Number of screens',
            inputScreenName: 'Please enter a screen name',
            editScreen: ' Modifying Screen information',
            screenID: 'Screen ID',
            controlPower: 'The right to control',
            installationLocation: 'The right to control',
            inputAddress: 'Please enter the screen details installation address',
            administrator: 'Administrative personnel',
            inputAdministrator: 'Please enter administrative personnel',
            phoneNumber: 'Contact number',
            inputPhoneNumber: 'Please enter a contact number',
            editDirTip: 'Changing the screen orientation will clear the playlist of the screen. Are you sure you want to change it?',
            screenJoined: 'Your screen has joined',
            editDirTip2: 'Changing the screen orientation will move the screen out of the group',
            editDirTip3: "Changing the screen orientation will clear the device's playlist",
            editPlan: 'Revise the plan',
            onOffSoon: 'Turn on or off immediately',
            planSetting: 'Plan setting',
            onSoon: 'Turn on immediately',
            offSoon: 'Turn off immediately',
            startUpTime: 'Boot time',
            shutDownTime: 'Shutdown time',
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            Sunday: 'Sunday',
            newPost: 'New placement',
            noPlan: 'No plan',
            longTermPlacement: 'Long-term placement',
            dailyRepeat: 'Repeat daily',
            playAllDay: 'All-day play',
            editProgram: 'Edit program',
            removeProgram: 'Remove program',
            crearProgram: 'Clear all programs',
            sureDelAllPrograms: 'Are you sure to delete all programs?',
            editProgramTip: 'Edit the program. All screens on which the program has been placed will also be changed accordingly.',
            continueEditProgram: 'Continue to edit program',
            daySpan: 'Span across days',
            yearSpan: 'Span across years',
            illegal: 'Illegal data. Processing of cross-day tags failed',
            illegal2: 'Illegal data. Processing of cross-year tags failed',
            advertisingSettings: 'Placement setting',
            selectAdvCon: 'Select placement content (single option)',
            settingPlan: 'Set play schedule',
            program: 'Program',
            material: 'Material',
            inputSearch3: 'Enter content name',
            keepConnected: 'Please keep the screen connected to the network',
            password: 'Password',
            netWorkInfoTip: 'Please confirm whether the network configuration information is correct to avoid the screen being unable to connect to the network.',
            confirmSet: 'Confirm network configuration',
            reset: 'Reset',
            netWorkInfoTip2: 'The network configuration information has been sent. Online screens will start to connect to this network after receiving it. If the connection fails, the screen will return to the original network or try to connect to this network again when the screen is offline',
            cancelDelivery: 'Cancel all placement',
            noDelivery: 'No placement',
            cancelDelivery2: 'Cancel placement',
            morePlan: 'More plan',
            screenDeployed: 'More plan',
            cancelDelivery3: 'Whether to cancel the placement',
            cancelDelivery4: 'Whether to cancel all placement',
            repeatersMag: 'Repeaters management',
            noTags: 'No ESL yet',
            replaceBind: 'Change bound data',
            unbindData: 'Unbind data',
            unbindTip: 'Are you sure to unbind all data?',
            startUnbind: 'Start unbinding data',
            repeaterTip: 'It’s only supported to add repeater through mini-program',
            unbind: 'Unbind',
            repeaterID: 'Repeater ID',
            structureNode: 'Organizational node',
            repeaterIP: 'Repeater IP',
            connectedCount: 'Number of connected ESL',
            registerTime: 'Register time',
            unbindTip2: 'After unbinding, the electronic paper displays under the repeater will be automatically deleted',
            unbinded: 'Unbound repeater',
            bindedData: 'Bound data',
            noBindedData: 'No bound data ',
            confirmReplaceBind: 'Confirm to change binding',
            dataCount: ' The number of template products on the screen is.',
            onlyChoose: 'You can only choose',
            transferRepeater: 'Repeater transfer',
            transferRepeaterTip: 'The operation will transfer all the electronic paper displays under the repeater to the corresponding organizational node (store).',
            large: '24/32/43/55 inch large screen',
            small: '10 inch small screen',
            planTip: 'Tips: If the screen has the same program, it will be replaced',
            selectDate: 'Select date',
            weeklySetting: 'Weekly setting',
            selectTime: 'Select time',
            nextYear: 'Next year',
            selectScreen: 'Select placement screen',
            confirmDeliver: 'Confirm to place',
            coverDelivery: 'Overlay placement',
            coverDeliveryTip: 'The placement will clear the playback schedule of the selected screens and only retain the programs placed this time.',

        },
        template: {
            moreTmpls: 'More AI template',
            inputSearch: 'Enter template name',
            noTmpl: 'No template',
            search: 'Search',
            product: 'Product',
            dir: 'Orientation',
            type: 'Type',
            useTmpl: 'Use template',
            template: 'Template',
            name: 'Template name',
            searchTmpl: 'Search template',
            otherMaterials: 'Jofee material',
            in: 'in',
            search2: 'search',
            size: 'Size',
            batchReplaceData: 'Batch replace data',
            binded: 'The template is bound',
            maxLimit: 'This template can be selected at most',
            qrcode: 'QR code',
            deviceQrcde: 'Device ID QR code',
            barcode: 'Barcode',
            countdown: 'Countdown',
            activityEnded: 'The event has ended and stay tuned',
            cover: 'Cover',
            create: 'Create',
            created: 'Created',
            reuse: 'Reuse ',
            upToMax: 'Have reached the maximum',
            resizeTip: 'After the size is changed, the recommended template will be switched accordingly',
            resize: 'Adjust the size',
            resizeTit: 'Adjust template size',
            addText: 'Add text',
            configureFont: 'Please configure the font',
            text: 'Text',
            inputText: 'Please enter text',
            check: 'Check',
            savedTo: 'The current template is saved to',
            saveSuccess: 'Save successfully',
            material: 'Material',
            component: 'Component',
            scale: 'Zoom',
            match: 'Adaptation',
            cut: 'Cut',
            copy: 'Copy',
            paste: 'Paste',
            down: 'Down',
            up: 'Up',
            bottom: 'Place at the bottom',
            top: 'Place at the top',
            crop: 'Crop',
            replace: 'Replace',
            lock: 'Lock',
            unlock: 'Unlock',
            sort: 'Sort',
            full: 'Full price',
            integer: 'Integral part',
            decimal: 'Decimal part',
            split: 'One-click split integer and decimal',
            join: 'One-click merge integer and decimal',
            searchFont: 'Search font',
            searchResult: 'Search result',
            currentFont: 'Current template font',
            alignLeft: 'Align left',
            alignCenter: ' Align center',
            alignRight: 'Align right',
            alignUp: 'Align up',
            alignVerCenter: 'Align vertically center',
            alignBottom: 'Align bottom',
            cn: 'CN',
            en: 'EN',
            cancelCombine: 'Ungroup',
            combine: 'Group',
            combineTip: 'Components containing data cannot be grouped',
            horDistribution: 'Horizontal distribution',
            verDistribution: 'Vertical distribution',
            dataAttributes: 'Data attribution',
            tmplSize: 'Template size',
            backgroundMusic: 'Background music',
            timerMode: 'Timing mode',
            countdownTip: 'After being selected, the countdown will continuously repeat between the start time and end time of each day.',
            startTime: 'Start time',
            endTime: 'End time',
            endText: 'Ending copy',
            countdownTip2: 'When the countdown ends, this copy will be displayed.',
            timerAccuracy: 'Timing accuracy',
            showUnit: 'Display unit',
            date: 'Date',
            hour: 'Hour',
            minute: 'Minute',
            second: 'Second',
            font: 'Font',
            parameterSpacing: 'Parameter spacing',
            replaceTmplTip: 'After using the template, the current page content will be replaced.',
            beyondTip: 'The number of data in the selected template is greater than that in the current template. Some data will be added. Do you want to continue the operation?',
            beyondTip2: 'The number of data in the selected template is less than that in the current template. Some data will be unbound. Do you want to continue the operation?',
            videoLimit: 'The template cannot add two videos',
            videoLimit2: 'Each template only allows one video to be added, and the video can only be at the bottommost layer.',
            videoLimit3: 'Video replication is not supported',
            price: 'Price',
            notCreated: 'Not yet created',
            inputName: 'Please enter a template name',
            unknown: 'Unnamed',
            invalidData: 'Invalid data',
        },
        program: {
            unit: '',
            noProgram: ' No program',
            layout: 'Layout',
            module: 'Module',
            contentList: 'Content list',
            playCount: 'Play times',
            times: 'Times',
            playDuration: 'Playback duration',
            seconds: 'Second',
            backgroundSetting: 'Background setting',
            size: 'Program resolution',
            backgroundMusic: 'Background music',
            voicebroadcast: 'Voice broadcast',
            inputName: 'Please enter the program name',
            saveAs: 'Save the program as',
            program: ' program',
            programs: ' programs',
            add: 'New program',
            search: 'Enter program name',
            name: 'Program name',
            delTip: 'After a program is deleted, the screen will not be able to play this program. Please operate with caution.',
            deliveredTo: 'The program has been released to',
            played: 'play',
            addBkgMusic: 'Add background music',
            resize: 'Adjust screen size',
            resize2: 'Adjust size',
            voice: 'Voice',
            speed: 'Speed',
            audition: 'Audition',
            inputContent: 'Please enter a custom broadcast content',
            example: 'Example',
            tts: 'Confirm the synthesized voice',
            merge: 'Merge programs',
            size2: 'Program size',
            mergeTip: 'Merging programs will not delete existing programs, but create a brand new program. Are you sure you want to merge?',
        },
        notFound: {
            notFound: ' Sorry! The page you visited does not exist.',
            reload: ' Reload the page',
        },
        employee: {
            name: 'Name',
            inputName: 'Please enter the name',
            role: 'Role',
            inviteUser: 'Invite users',
            remove: 'Remove enterprises',
            avatar: 'User avatar',
            belongTo: 'Belonging organizational structure',
            inviter: 'Inviter',
            removeTip: 'Are you sure to remove employee',
            removeTip2: 'out of your enterprise',
            inviteTit: 'The invitation link has been generated. Let employees visit this link to join the enterprise.',
            effectiveTime: 'This link will expire after 24 hours.',
            click: 'Click to copy',
            setUser: 'Set user',
            userName: 'User name',
            noteName: 'Remark name',
            userRole: 'User role',
            generateLink: 'Generate link',
        },
        role: {
            name: 'Role name',
            note: 'Note',
            creater: 'New user',
            createTime: 'Create time',
            delTip: ' Are you sure to delete the role',
            add: ' New role',
            edit: 'Edit role',
        },
        setting: {
            companyInfo: 'Enterprise information',
            upload: 'Upload',
            superAdmin: 'Super administrator',
            companyID: 'Enterprise ID',
            companyIndustry: 'Enterprise industry',
            change: 'Change',
            selectTit: 'Please select the current industry of the enterprise',
            selectTip: 'The system will recommend templates and other industry data for you according to the industry you selected.',
            openEnterprise: 'Open enterprise',
            industry: 'Industry',
            enterpriseName: 'Enterprise name',
            inputEnterpriseName: 'Please enter the enterprise name',
            transfer: 'Transfer enterprise',
            inputPhoneNumberTip: 'Enter the mobile phone number of the transferred user. This user will become the super administrator of the enterprise.',
            transferTip: 'After transferring the enterprise, you will leave the enterprise. Are you sure to transfer.',
            peddingEnterprise: 'Pending enterprise',
            unit: '',
            employee: 'employee',
            employees: 'employees',
            join: 'Join the enterprise',
            reject: "Reject the enterprise's invitation",
            accept: "Accept the enterprise's invitation",
            inviteJoining: 'Invite to join the enterprise',
            selfCreated: 'The enterprise I created',
            cancelEnterprise: 'Cancel the enterprise',
            cancelTransfer: 'Cancel the transfer',
            transfering: 'In transfer',
            joinedCompanies: 'The enterprise I joined.',
            leave: 'Leave the enterprise',
            cancelTip: 'After canceling the enterprise, the resources and equipment of the enterprise will be deleted together. Please operate with caution',
            reminderAgain: 'Reminder again.',
            cancelSuccess: 'Cancellation successful.',
            sureJoin: 'Are you sure to join?',
            sureReject: 'Confirm rejection?',
            acceptTip: 'After accepting the enterprise, you will become the super administrator of this enterprise. Are you sure to accept?',
            sureCancel: 'Are you sure to cancel the transfer?',
            sureLeave: 'Are you sure to leave this enterprise?',
            leaveSuccess: 'Successfully leave.',
        },
        structure: {
            superiorGroup:  'Upper-level grouping',
            limit: 'At most five digits.',
            newSubordinate: 'New subordinate',
            editGroup: 'Edit group',
            addGroup: 'New group',
            subordinate: 'Subordinate',
        },
        personal: {
            accountSetting: 'Account setting',
            myCompany: 'My enterprise',
            phone: 'Phone',
            wechat: 'WeChat',
            binded: 'Bound',
            unbinded: 'Unbound',
            bind: 'Bind',
            unbind: 'Unbind',
            setted: 'Set up',
            notSetted: 'Not set',
            myIndustry: 'My industry',
            unbindWechat: 'Unbind from WeChat',
            unbindWechatTip: 'After unbinding, you will not be able to log in to this account with this WeChat ID. Please operate with caution.',
            think: 'Do not unbind for the time being',
            confirmUnbind: 'Confirm to unbind',
            selectIndustry: 'Please select your industry',
            bindPhone: 'Bind a new mobile phone.',
            currentPhone: 'Current mobile phone',
            unbindedTip: 'After unbinding, you will not be able to log in to this account with this mobile phone number.',
            chandeSuccess: 'Change successfully',
            settingPassword: 'Set password',
            bindWechat: 'Bind WeChat.',
            scan: 'Scan the code',
            follow: 'Follow',
            bindedSuccess: 'Bound successfully.',
        }
    },
};